<template>
  <el-form size="mini" ref="form" :model="formData" :rules="rules" label-width="120px" status-icon>
    <el-form-item label="原密码" prop="oldPassword">
      <el-input type="password" placeholder="请输入原密码" v-model="formData.oldPassword" auto-complete="off"></el-input>
    </el-form-item>
    <el-form-item label="新密码" prop="newPassword">
      <el-input type="password" placeholder="请输入新密码" v-model="formData.newPassword" auto-complete="off"></el-input>
    </el-form-item>
    <el-form-item label="新密码确认" prop="checkPassword">
      <el-input type="password" placeholder="请再次输入新密码" v-model="formData.checkPassword" auto-complete="off"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="handleSubmit()" v-waves>确定</el-button>
    </el-form-item>
  </el-form>
</template>

<style scoped>
.el-form {
  margin: 20px;
}
</style>

<script>
import waves from '@/directive/waves' // 水波纹指令
import adminApi from '@/apis/admin/admin'

export default {
  directives: {
    waves
  },
  data () {
    return {
      formData: {
        newPassword: undefined,
        checkPassword: undefined,
        oldPassword: undefined
      },
      rules: {
        oldPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === '' || typeof value === 'undefined') {
                callback(new Error('请输入原密码'))
              } else if (value.length < 6 || value.length > 20) {
                callback(new Error('密码长度需在6到20之间'))
              } else {
                callback()
              }
            },
            trigger: 'change'
          }
        ],
        newPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === '' || typeof value === 'undefined') {
                callback(new Error('请输入新密码'))
              } else if (value.length < 6 || value.length > 20) {
                callback(new Error('密码长度需在6到20之间'))
              } else {
                if (this.formData.checkPassword !== '') {
                  this.$refs.form.validateField('checkPassword')
                }
                callback()
              }
            },
            trigger: 'change'
          },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: '密码必须是数字和字母组合'
          }
        ],
        checkPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === '' || typeof value === 'undefined') {
                callback(new Error('请再次输入密码'))
              } else if (value.length < 6 || value.length > 20) {
                callback(new Error('密码长度在6到20个字符'))
              } else if (value !== this.formData.newPassword) {
                callback(new Error('两次输入密码不一致'))
              } else {
                callback()
              }
            }
          },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: '密码必须是数字和字母组合'
          }
        ]
      }
    }
  },
  methods: {
    /**
     * 提交数据
     */
    handleSubmit () {
      this.$refs.form.validate().then(response => {
        adminApi.changePassword(this.formData).then(response => {
          this.formData.newPassword = undefined
          this.formData.oldPassword = undefined
          this.formData.checkPassword = undefined
          this.$refs.form.resetFields()
          this.$message({
            title: '成功',
            message: `密码修改成功`,
            type: 'success'
          })
          this.$store.dispatch('LogOut').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        })
      })
    }
  }
}
</script>
